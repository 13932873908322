import React from "react";

export const Profile = (props: any) => {
  return (
    <>
      <div>
        <h1>SITE UNDER DEVELOPMENT</h1>
      </div>
    </>
  );
};
