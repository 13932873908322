import React from "react";

import "./SungkaBanner.scss";

export const SungkaBanner = () => {
  return (
    <div className="sungka-banner">
      <div>Sungka</div>
      <div>-</div>
      <div>Live</div>
      <div>-</div>
      <div>Online</div>
    </div>
  );
};
